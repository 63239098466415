// src/components/LoadingSpinner/LottieLoader.js
import React from 'react';
import Lottie from 'lottie-react';
import LoaderAnimation from '../../assets/Images/Loader.json';
import './LoadingSpinner.css';

const LottieLoader = ({ message = "Loading...", fullscreen = true }) => {
  return (
    <div className={fullscreen ? "loading-overlay" : "lottie-inline"}>
      <div className="lottie-wrapper">
        <Lottie
          animationData={LoaderAnimation}
          loop={true}
          className="lottie-animation"
        />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default LottieLoader;
