import React, { useEffect, useState, useMemo } from 'react';
import './ImageLibraryModal.css';
import LottieLoader from '../LoadingSpinner/LottieLoader';
import useInView from '../../hooks/useInView'; // Adjust this if needed

const ImageLibraryModal = ({ onClose, onImageSelect }) => {
  const categories = useMemo(() => {
    const catList = [
      "Transport", "Fruit", "Animals", "Jobs", "Dinosaurs", "Shapes", "Sign Language",
      "School", "Weather", "Diddy Bots", "Diddy Bots Assets", "Emotions", "Places", "Verbs",
      "Sports", "Clothing", "Vegetables", "Science", "Food & Cutlery", "Home & Garden",
      "Flags", "Body", "Family", "Toys", "Jigsaws", "Special Days"
    ];
  
    return ["All", ...catList.sort((a, b) => a.localeCompare(b))];
  }, []);
  

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [images, setImages] = useState([]);
  const [imageCache, setImageCache] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [preserveWhiteFill, setPreserveWhiteFill] = useState(true);

  useEffect(() => {
    if (imageCache[selectedCategory]) {
      setImages(imageCache[selectedCategory]);
    } else {
      fetchImages(selectedCategory);
    }
  }, [selectedCategory, imageCache]);

  const fetchImages = async (category) => {
    setIsLoading(true);
    try {
      let images = [];
      if (category === "All") {
        const categoriesToFetch = categories.filter(cat => cat !== "All");
        const results = await Promise.all(
          categoriesToFetch.map(async (cat) => {
            if (imageCache[cat]) return imageCache[cat];
            const response = await fetch(`${process.env.PUBLIC_URL}/Icons/${cat}/index.json`);
            const categoryImages = await response.json();
            return categoryImages.map((image) => ({ ...image, category: cat }));
          })
        );
        images = results.flat();
      } else {
        const response = await fetch(`${process.env.PUBLIC_URL}/Icons/${category}/index.json`);
        images = (await response.json()).map((image) => ({ ...image, category }));
      }

      setImageCache(prevCache => ({ ...prevCache, [category]: images }));
      setImages(images);
    } catch (error) {
      console.error("Failed to load images", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredImages = useMemo(() => {
    return images.filter(image =>
      image.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [images, searchTerm]);

  return (
    <div className="image-library-modal-overlay">
      <div className="image-library-modal">
        <div className="image-library-header">
          <h2>Image Library</h2>
          <button onClick={onClose} className="image-library-close-btn" aria-label="Close Modal">
            ×
          </button>
        </div>

        <div className="image-library-search-bar">
          <input
            type="text"
            placeholder="Search images..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div style={{ margin: '10px 0', paddingLeft: '10px' }}>
          <label style={{ cursor: 'pointer' }}>
            <input
              type="checkbox"
              checked={preserveWhiteFill}
              onChange={() => setPreserveWhiteFill(!preserveWhiteFill)}
              style={{ marginRight: '6px' }}
            />
            Fill
          </label>
        </div>

        <div className="image-library-content">
          <div className="image-library-sidebar">
            <ul>
              {categories.map((category) => (
                <li key={category}>
                  <button
                    className={`image-library-category-btn ${selectedCategory === category ? 'active' : ''}`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="image-library-grid-wrapper">
            <div className="image-library-grid">
              {filteredImages.length > 0 ? (
                filteredImages.map((image, index) => (
                  <LazyImageCard
                    key={index}
                    image={image}
                    preserveWhiteFill={preserveWhiteFill}
                    onImageSelect={onImageSelect}
                  />
                ))
              ) : (
                !isLoading && <p>No images match your search.</p>
              )}
            </div>

            {isLoading && (
              <div className="image-library-loader-overlay">
                <LottieLoader message="Loading Images..." fullscreen={false} />
              </div>
            )}
          </div>


        </div>
      </div>
    </div>
  );
};

export default ImageLibraryModal;

// 🧩 Lazy-loaded Image Card component
const LazyImageCard = ({ image, preserveWhiteFill, onImageSelect }) => {
  const [ref, isInView] = useInView({ threshold: 0.1 });

  const handleClick = async () => {
    const url = `${process.env.PUBLIC_URL}/Icons/${image.category}/${image.file}`;

    if (!preserveWhiteFill && image.file.endsWith('.svg')) {
      try {
        const response = await fetch(url);
        const svgText = await response.text();

        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const svgEl = svgDoc.documentElement;

        if (!svgEl.getAttribute("xmlns")) {
          svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        }

        const elements = svgDoc.querySelectorAll('[fill], [style]');
        elements.forEach(el => {
          const fill = el.getAttribute("fill");
          const style = el.getAttribute("style");

          if (fill && ["#fff", "#ffffff", "white"].includes(fill.toLowerCase())) {
            el.setAttribute("fill", "transparent");
          }

          if (style && /fill\s*:\s*(white|#fff|#ffffff)/gi.test(style)) {
            el.setAttribute("style", style.replace(/fill\s*:\s*(white|#fff|#ffffff)/gi, "fill:transparent"));
          }
        });

        const styleTags = svgDoc.querySelectorAll("style");
        styleTags.forEach(tag => {
          tag.textContent = tag.textContent.replace(
            /fill\s*:\s*(white|#fff|#ffffff)/gi,
            "fill:transparent"
          );
        });

        const serializer = new XMLSerializer();
        const fullSVG = serializer.serializeToString(svgDoc);
        const encoded = encodeURIComponent('<?xml version="1.0"?>' + fullSVG);
        const dataUrl = `data:image/svg+xml;charset=utf-8,${encoded}`;

        onImageSelect({
          src: dataUrl,
          name: image.name,
          width: 100,
          height: 100,
        });
      } catch (error) {
        console.error("Error processing SVG:", error);
      }
    } else {
      onImageSelect({
        src: url,
        name: image.name,
        width: 100,
        height: 100,
      });
    }
  };

  return (
    <div
      ref={ref}
      className="image-library-card"
      onClick={handleClick}
    >
      <p className="image-library-name">{image.name}</p>
      {isInView && (
        <img
          src={`${process.env.PUBLIC_URL}/Icons/${image.category}/${image.file}`}
          alt={image.name}
          className="image-library-image fade-in"
        />
      )}
    </div>
  );
};
